import { ScrollPosStyler } from 'scrollpos-styler';
// import ScrollReveal from 'scrollreveal';
import reframe from 'reframe.js';

import Swiper from 'swiper/bundle';

// Swiper.use([Navigation, Pagination, Autoplay]);

export default {
	init() {
		// JavaScript to be fired on all pages

		/**
		 * Navbar toggler with overlay menu and attach class menu-open to body
		 */
		const navbarTogglerAriaState = document.querySelector('.navbar-toggler');
		$(document).on('click', '.navbar-toggler', function (e) {
			e.preventDefault();
			navbarTogglerAriaState.ariaExpanded = !JSON.parse(
				navbarTogglerAriaState.ariaExpanded
			);
			$('body').toggleClass('menu-open');

			/**
			 * Get navbar dropdown menus
			 */
			const navbarDropdownMenu = document.querySelectorAll(
				'.navbar-nav li.menu-item.dropdown ul.dropdown-menu'
			);

			// Remove the d-block class from the navbar dropdown menu
			if ($(navbarDropdownMenu).hasClass('d-block')) {
				$(navbarDropdownMenu).removeClass('d-block');
			}
		});

		/**
		 * Navbar toggler animation function (Hamburger menu)
		 */
		const TOGGLER = document.querySelector('button.navbar-toggler');

		const HANDLE_TOGGLER = () => {
			TOGGLER.setAttribute(
				'aria-pressed',
				TOGGLER.matches('[aria-pressed=true]') ? false : true
			);
		};

		TOGGLER.addEventListener('click', HANDLE_TOGGLER);

		// If the inner width of the window is greater then 768px
		if (window.innerWidth < 1200) {
			$(document).on(
				'click',
				'.navbar-nav li.menu-item.dropdown a.nav-link',
				function (e) {
					e.preventDefault();

					if ($(this).closest('li').hasClass('has-dropdown')) {
						$(this).closest('li').addClass('has-dropdown');
					} else {
						$(this).closest('li').removeClass('has-dropdown');
						$(this).siblings('ul.dropdown-menu').toggleClass('d-block');
					}
				}
			);
		}

		/**
		 * Initialize Swiper Logos CPT
		 */
		new Swiper('.swiper-logos-cpt', {
			grabCursor: true,
			loop: true,
			navigation: {
				nextEl: '.section-logos-cpt .swiper-btn-next',
				prevEl: '.section-logos-cpt .swiper-btn-prev',
			},
			pagination: {
				el: '.swiper-logos-cpt-pagination',
				// dynamicBullets: true,
			},
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 32,
			autoplay: {
				delay: 4000,
			},
			breakpoints: {
				480: {
					slidesPerView: 5,
					slidesPerGroup: 4,
				},
				1024: {
					slidesPerView: 6,
					slidesPerGroup: 5,
				},
			},
		});

		new Swiper('.swiper-type-cards', {
			grabCursor: true,
			loop: true,
			navigation: {
				nextEl: '.swiper-type-cards .swiper-btn-next',
				prevEl: '.swiper-type-cards .swiper-btn-prev',
			},
			pagination: {
				el: '.swiper-cards-pagination',
				// dynamicBullets: true,
			},
			slidesPerView: 1,
			spaceBetween: 32,
			autoplay: {
				delay: 4100,
			},
			breakpoints: {
				480: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		});

		new Swiper('.swiper-main-header', {
			grabCursor: true,
			loop: true,
			slidesPerView: 1,
			spaceBetween: 0,
			autoplay: {
				delay: 4000,
			},
			pagination: {
				el: '.swiper-main-header-pagination',
			},
		});

		new Swiper('.swiper-features', {
			grabCursor: true,
			loop: true,
			slidesPerView: 1,
			spaceBetween: 32,
			autoplay: {
				delay: 4100,
			},
			breakpoints: {
				480: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
					// slidesPerGroup: 2,
				},
			},
			pagination: {
				el: '.swiper-features-pagination',
			},
		});

		/**
		 * Function that will copy URL to clipboard
		 */
		if (document.getElementById('copyUrlButton')) {
			document
				.getElementById('copyUrlButton')
				.addEventListener('click', function () {
					// Get the current URL
					const CURRENTURL = window.location.href;

					// Create a temporary input element to copy the URL to the clipboard
					const TEMPINPUT = document.createElement('input');
					TEMPINPUT.value = CURRENTURL;
					document.body.appendChild(TEMPINPUT);
					TEMPINPUT.select();
					document.execCommand('copy');
					document.body.removeChild(TEMPINPUT);

					// Show the tooltip to indicate successful copying
					$('#copyUrlButton')
						.attr('title', 'URL copied to clipboard')
						.tooltip('show');

					// Hide the tooltip after a short delay
					setTimeout(function () {
						$('#copyUrlButton')
							.attr('data-bs-original-title', 'Copy URL')
							.tooltip('hide');
					}, 1000);
				});
		}

		/**
		 * Scrollpos styler
		 */
		ScrollPosStyler;

		/**
		 * Initialize reframe.js
		 */
		reframe('.video-embed iframe');

		/**
		 * Display the campaign end message in a modal
		 */
		$(document).ready(function () {
			setTimeout(function () {
				$('.modal-campaign-end').addClass('show').show();
				$('.modal-backdrop-campaign-end').addClass('show');
			}, 1000);

			$('.btn-close-modal').on('click', function () {
				//remove the backdrop and modal
				$('.modal-backdrop-campaign-end').remove();
				$('.modal-campaign-end').remove();
			});
		});
	},
};
